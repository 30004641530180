import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthSharedActions } from '@chassis/auth/shared';
import { NotificationsService } from '@chassis/shared/notifications';
import { ElectronService } from '@chassis/shared/services/electron';
import { IpcService } from '@chassis/shared/services/ipc';
import { PushNotificationsService } from '@chassis/push-notifications';
import { SharedActions } from '@chassis/shared/actions';
import { ShortcutsUiService } from '@chassis/shared/services/shortcuts';
import { filter } from 'rxjs';
import LogRocket from 'logrocket';
import { IpcChannels } from '@chassis/shared/models';
import { LaunchDarklyService } from '@chassis/shared/services/launch-darkly';
import { LoggerService } from '@chassis/shared/services/logger';
import { PwaUpdaterService } from '@chassis/about';

@Component({
  selector: 'spark-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    @Optional() private ipcService: IpcService,
    private store: Store,
    private notificationsService: NotificationsService,
    private electronService: ElectronService,
    private pushNotificationsService: PushNotificationsService,
    private renderer: Renderer2,
    private router: Router,
    private ngZone: NgZone,
    private shortcutsUiService: ShortcutsUiService,
    private launchDarklyService: LaunchDarklyService,
    private logger: LoggerService,
    private pwaUpdaterService: PwaUpdaterService,
  ) {}

  async ngOnInit() {
    this.store.dispatch(SharedActions.initPreAuth());
    if (this.electronService.isElectron) {
      this.store.dispatch(SharedActions.initDesktopPreAuth());
      await this.initIpcService();
    }
    this.store.dispatch(AuthSharedActions.getJWT());

    this.initRouterHandler();

    this.pwaUpdaterService.initialize();
  }

  ngOnDestroy() {
    this.store.dispatch(SharedActions.teardown());
  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  async initIpcService() {
    if (!this.ipcService) {
      return;
    }

    this.ipcService.hollanderSearch$
      .pipe(filter((v): v is { hollanders: string[]; key: string } => !!v))
      .subscribe(({ hollanders, key }) => {
        LogRocket.log('ScreenShotResult: ', { key, hollanders });

        this.ngZone.run(() => {
          this.router.navigate(['/search'], {
            queryParams: { term: hollanders[0] },
          });
        });
      });

    this.ipcService.receive(IpcChannels.shortcutProcessing, () => {
      this.ngZone.run(() => {
        this.router.navigate(['/search'], { queryParams: { shortcut: true } });
      });
    });

    this.ipcService.receive(IpcChannels.log, (args: any[]) => {
      this.logger.log(...args);
    });
  }

  initRouterHandler() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (/auth/.test(event.url)) {
          this.renderer.addClass(document.body, 'auth-image');
        } else {
          this.renderer.removeClass(document.body, 'auth-image');
        }
      }
    });
  }
}
