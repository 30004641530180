{
  "$schema": "../../node_modules/@angular/service-worker/config/schema.json",
  "appData": {
    "version": "0.18.23"
  },
  "assetGroups": [
    {
      "installMode": "prefetch",
      "name": "app",
      "resources": {
        "files": [
          "/favicon.ico",
          "/index.html",
          "/manifest.webmanifest",
          "/*.css",
          "/*.js",
          "/firebase-messaging-sw.js"
        ]
      }
    },
    {
      "installMode": "lazy",
      "name": "assets",
      "resources": {
        "files": [
          "/assets/**",
          "/*.(svg|cur|jpg|jpeg|png|apng|webp|avif|gif|otf|ttf|woff|woff2)"
        ]
      },
      "updateMode": "prefetch"
    }
  ],
  "dataGroups": [],
  "index": "/index.html"
}